<template>

	<div class="mt-50 text-left">
		<div class="">
			<div
			>
				<h3 class="mr-10">사용자 검색결과</h3>
			</div>
			<div
				class="box-list mt-30"
			>
				<ul
					v-if="items_member.length > 0"
					class="flex-row"
				>
					<li
						v-for="(item, index) in items_member"
						:key="'item_' + index"
						class="inline-block text-center hover-scale pa-10 radius-20"
						@click="on098(item)"
					>
						<div
							class="square-120 radius-20 box-img"
						>
							<img
								:src="$request.upload_url(item.profle_nft_card_img_url)" :alt="item.nickname"
								@error="$bus.$emit('onErrorProfileImage', $event)"
								class="width-100 object-cover"
							>
						</div>
						<div class="">
							<strong class="font-weight-500 color-333">{{ item.nickname }}</strong>
						</div>
					</li>
				</ul>
				<div
					v-else
					class="none"
				>검색된 사용자가 없습니다</div>
			</div>
		</div>

		<div class="mt-50">
			<div
				class="box-title"
			>
				<h3 class="mr-10">카르텔 검색결과</h3>
			</div>
			<div
				class="box-list mt-30"
			>
				<ul
					v-if="items_cartel.length > 0"
					class="flex-row-wrap"
					style="row-gap: 30px; column-gap: 20px;"
				>
					<li
						v-for="(cartel, index) in items_cartel"
						:key="'cartel_' + index"
						class="inline-block cursor-pointer hover-scale pa-10 radius-20"
						@click="toDetail(cartel)"
					>
						<div
							class="flex-row align-center"
						>
							<div class="square-120 radius-20 mr-15 box-img">
								<img
									:src="cartel.cartl_img_url"
									@error="$bus.$emit('onErrorCartelProfileImage', $event)"
									class="width-100 object-cover"
								/>
							</div>

							<div
								class="box-info flex-1 overflow-hidden"
							>

								<Marquee class="title font-weight-500">
									<template
										v-slot:text
									>{{  cartel.cartl_name }}</template>
								</Marquee>
								<div class="count mt-10 flex-row size-px-12"><span class="flex-1 color-333">전체글</span> <span class="flex-2 color-888">{{  cartel.all_articles_count | makeComma }}</span></div>
								<div class="count mt-5 flex-row size-px-12"><span class="flex-1 color-333">새글</span> <span class="flex-2 color-888">{{  cartel.nwpost_count | makeComma }}</span></div>
							</div>
						</div>
					</li>
				</ul>

				<div
					v-else
					class="none"
				>검색된 카르텔이 없습니다</div>
			</div>
		</div>

		<div class="mt-50">
			<div
				class="box-title"
			>
				<h3 class="mr-10">전체 카르텔 <span class="h6">메타마피아에 등록된 카르텔을 확인하세요</span></h3>
			</div>
			<div
				class="box-list mt-30"
			>
				<ul
					v-if="items_total.length > 0"
					class="flex-row-wrap"
					style="row-gap: 30px; column-gap: 20px;"
				>
					<li
						v-for="(cartel, index) in items_total"
						:key="'cartelt_' + index"
						class="inline-block cursor-pointer "
						@click="toDetail(cartel)"
					>
						<div
							class="flex-row align-center hover-scale pa-10 radius-20"
						>
							<div class="square-120 radius-20 mr-15 box-img ">
								<img
									:src="cartel.cartl_img_url"
									@error="$bus.$emit('onErrorCartelProfileImage', $event)"
									class="width-100 object-cover"
								/>
							</div>

							<div
								class="box-info flex-1 overflow-hidden"
							>
								<Marquee class="title font-weight-500">
									<template v-slot:text>{{  cartel.cartl_name }}</template>
								</Marquee>
								<div class="count mt-10 flex-row size-px-12"><span class="flex-1 color-333">전체글</span> <span class="flex-2 color-888">{{  cartel.all_articles_count | makeComma }}</span></div>
								<div class="count mt-5 flex-row size-px-12"><span class="flex-1 color-333">새글</span> <span class="flex-2 color-888">{{  cartel.nwpost_count | makeComma }}</span></div>
							</div>
						</div>
					</li>
				</ul>

				<div
					v-else
					class="none"
				>검색된 카르텔이 없습니다</div>
			</div>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="items_cartel"
			:user="user"
			:friend="item_user"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 99; background-color: white"
		></mafia098>
	</div>
</template>

<script>
	import mafia098 from "@/view/Cartel/mafia098"
	import Marquee from "@/components/Marquee";
	export default {
		name: 'mafia047'
		, props: ['user']
		, components: {Marquee, mafia098 }
		, data: function(){
			return {
				program: {
					name: '카르텔 검색'
					, title: '검색'
					, from: 'mafia044'
				}
				, item_search: {
					member_number: this.user.member_number
					, search_value: this.$route.params.search_value
				}
				, is_search: false
				, items_cartel: []
				, items_member: []
				, view_type: ''
				, is_098: false
				, item_user: {}
				, items_total: []
				, item_search_total: {
					page: 1
					, list_cnt: 100
				}
			}
		}
		, computed: {
			txt_search: function(){
				let t = '검색어를 입력하세요'

				if(this.is_search && this.items_cartel.length == 0 && this.items_member.length == 0){
					t = '검색결과가 없습니다'
				}

				return t
			}
		}
		, methods: {
			getSearch: async function(){
				try{
					if(!this.item_search.search_value){
						throw this.$language.common.error_empty_search_value
					}
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_main_search
						, data: {
							member_number: this.user.member_number
							, srchtext: this.item_search.search_value
						}
						, type: true
					})

					if(result.success){
						this.items_member = result.data.user_search_result
						this.items_cartel = result.data.cartl_search_result
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getMembers: async function(){

				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_member_list
						, data: this.$storage.init({
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						})
						, type: true
					})

					if(result.success){
						this.items_member = result.data.member_list
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getData: async function(){

				try{
					this.view_type = ''
					if(!this.item_search.search_value){
						//throw '검색어를 입력하세요'
					}
					try{
						this.$bus.$emit('on', true)
						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.get_cartel_total_search
							, data: this.$storage.init({
								member_number: this.user.member_number
								, srchtext: this.item_search.search_value
							})
							, type: true
						})

						if(result.success){
							this.items_member = result.data.user_search_result
							this.items_cartel = result.data.cartl_search_result
						}else{
							throw result.message
						}
					}catch (e) {
						this.$log.console(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.is_search = true
						this.$bus.$emit('on', false)
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}

			, getCartelList: async function(){

				try{
					this.view_type = ''
					try{
						this.$bus.$emit('on', true)
						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.get_cartel_total_list
							, data: {
								member_number: this.user.member_number
								, page_number: this.item_search_total.page
								, pagerecnum: this.item_search_total.list_cnt
							}
							, type: true
						})

						if(result.success){
							this.items_total = result.data.cartl_list
						}else{
							throw result.message
						}
					}catch (e) {
						this.$log.console(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.is_search = true
						this.$bus.$emit('on', false)
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, goBack: function(){
				if(this.view_type == 'member'){
					this.view_type = ''
				}else{
					this.$storage.push({ name: this.program.from })
				}
			}
			, on098: function(user){
				this.$log.console('user', user)
				this.is_098 = true
				this.item_user = user
			}
			, toDetail: function(item){
				this.$bus.$emit('to', { name: 'mafia049', params: {idx: item.cartl_number}})
			}
		}
		,created() {
			this.$bus.$emit('onLoad', this.program)
			this.$log.console(this.$route)
			this.getSearch()
			this.getCartelList()
		}
		, watch: {
			$route(to){
				this.item_search.search_value = to.params.search_value
				this.getSearch()
			}
		}
	}
</script>

<style scoped>
.box-list ul {
	text-align: left;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 34px;
	flex-wrap: wrap;
}
.box-list ul li{
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
    cursor: pointer;
}

.box-list li .box-img{
	width: 120px; height: 120px; min-width: 120px;
	border-radius: 0;
	border: none;
	overflow: hidden;
	position: relative;
	background-color: #eee;
	-webkit-mask-image: url(../../assets/image/web/box_mask_120.png);
		mask-image: url(../../assets/image/web/box_mask_120.png);
}
.box-list .flex-row-wrap{
	/* column-gap: 20px; */
	row-gap: 30px;
	column-gap: 0px;
}
.box-list .flex-row-wrap li{
	/* flex-basis: 25%;
	max-width: 25%; */
}
.box-info .title{
	overflow: hidden;

	text-overflow: ellipsis;
	word-break: break-all;
	-webkit-line-clamp: 2;
	width: 130px;
}
.box-info .count{
	align-items: baseline;
	text-align: left;
}
</style>